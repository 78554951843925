body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Styling for HTML Elements */
.MuiTypography-root.MuiLink-root {
  text-decoration: none;
  color: #90caf9;
}

.copy-to-clipboard-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #90caf9;
  text-decoration: none;
}
.copy-to-clipboard-btn:hover,
.copy-to-clipboard-btn:hover {
  color: #c1dff7;
  background: #585858;
  border-radius: 5px;
}

.generate-chapters-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #90caf9;
  background: #585858;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 5px;
}
.generate-chapters-btn:hover,
.generate-chapters-btn:hover {
  color: #c1dff7;
  background: #585858;
  border-radius: 5px;
}

.generate-chapters-btn:disabled {
  opacity: 0.6;
  cursor: no-drop;
}

.launch-link {
  /* margin-left: 8px!important; */
  height: 15px;
  color: #90caf9;
}
.launch-link * {
  width: 15px !important;
  height: 15px !important;
  fill: #90caf9;
}

a {
  color: rgb(144, 202, 249);
  text-decoration: none;
}

tr:not(.RaDatagrid-clickableRow.MuiTableRow-hover):hover {
  background-color: transparent;
}

.css-ejmsgm-RaDatagrid-root .RaDatagrid-headerCell {
  text-align: left;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.data-cell__copy-to-clipboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.profile-picture {
  width: 88px;
  height: 88px;
  object-fit: cover;
}

.profile-picture-banner {
  width: 166px;
  height: 88px;
  object-fit: cover;
}

.add-file {
  display: none;
}

.file-label {
  margin-top: 0;
}

.file-line button {
  padding: 5px 0;
  appearance: none;
  border-radius: 4px;
  margin-right: 20px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.btn-replace-image,
.btn-add-image {
  color: #90caf9;
}

.btn-delete-image {
  color: #ed5d68;
}

.file-image {
  width: 100%;
  height: auto;
}

.file-line p {
  cursor: pointer;
  margin: 0;
  margin-top: 10px;
}

.crop-container {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(23, 23, 23, 1);
}
.crop-container .crop-buttons {
  position: absolute;
  bottom: 0;
}
.crop-container .crop-buttons .btn {
  background: rgba(23, 23, 23, 1);
  color: #fff;
  appearance: none;
  padding: 10px 0;
  border: thin solid white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
}

.crop-container .crop-buttons .btn-crop-done {
  background: #90caf9;
  border: none;
  color: #000000;
}

.crop-container .crop-buttons .form-buttons {
  display: flex;
  justify-content: center;
}
.crop-container .crop-buttons .form-buttons .form-radio span {
  margin: 0 5px;
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  .crop-container.croppedForm .reactEasyCrop_Container {
    bottom: 170px;
  }
  .crop-container .reactEasyCrop_Container {
    bottom: 80px;
  }
  .crop-container .crop-buttons {
    padding: 16px;
    width: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .crop-container .crop-buttons .btn {
    width: 100%;
    font-size: 14px;
  }
  .crop-container .crop-buttons .btn.btn-crop-close {
    margin-top: 16px;
  }
  .crop-container .crop-buttons .form-radio {
    padding: 15px;
  }
}
@media only screen and (min-width: 577px) and (max-width: 1199px) {
  .crop-container.croppedForm .reactEasyCrop_Container {
    bottom: 110px;
  }
  .crop-container .reactEasyCrop_Container {
    bottom: 80px;
  }
  .crop-container .crop-buttons {
    padding-bottom: 15px;
    width: 400px;
    left: calc(50% - 200px);
  }
  .crop-container .crop-buttons .btn {
    width: 180px;
  }
  .crop-container .crop-buttons .btn.btn-crop-close {
    margin-right: 40px;
  }
  .crop-container .crop-buttons .form-radio {
    padding: 15px 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .crop-container.croppedForm .reactEasyCrop_Container {
    bottom: 110px;
  }
  .crop-container .reactEasyCrop_Container {
    bottom: 80px;
  }
  .crop-container .crop-buttons {
    padding-bottom: 15px;
    width: 400px;
    left: calc(50% - 200px);
  }
  .crop-container .crop-buttons .btn {
    width: 180px;
  }
  .crop-container .crop-buttons .btn.btn-crop-close {
    margin-right: 40px;
  }
  .crop-container .crop-buttons .form-radio {
    padding: 15px 30px;
  }
}

.empty-text {
  margin: 10px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}
